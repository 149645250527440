import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import NotFound from '../views/NotFound.vue';
import BigData from '../views/BigData.vue';
import Legal from '../views/Legal.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/bigdata',
    name: 'bigData',
    component: BigData
  },
  {
    path: '/legal_disclaimer',
    name: 'legal_disclaimer',
    component: Legal
  },



  {
    path: '/404',
    alias: '*',
    name: 'notFound',
    component: NotFound
  }
]

const router = new VueRouter({
  linkExactActiveClass: 'activeSiteRoute',
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
