module.exports = Object.freeze({
    bigData: [
        { text: 'Address', align: 'start', value: 'full_address'},
        { text: 'City', align: 'start', value: 'city.name', filterable: false},
        { text: 'Neighborhood', align: 'start', value: 'neighborhood.name', filterable: false},
        { text: 'Collections', align: 'start', value: 'collections'},
        { text: 'Mint Price', align: 'start', value: 'mint_price', filterable: false},
        { text: 'Last Price', align: 'start', value: 'last_purchased_price', filterable: false},
        { text: 'UP2', align: 'start', value: 'area', filterable: false},
        { text: 'Sale Price', align: 'start', value: 'price', filterable: false},
        { text: 'Currency', align: 'start', value: 'on_market.currency', filterable: false},
        { text: 'Markup', align: 'start', value: 'on_market.markup', filterable: false},
        { text: 'Owner', align: 'start', value: 'owner_username', filterable: false},
        { text: 'Status', align: 'start', value: 'status', filterable: false},
        { text: 'Actions', value: 'actions', filterable: false, sortable: false, align: 'center'},

    ]
})