<template>
    <nav id="nav">
        <v-toolbar dense flat class="d-none d-sm-block">
            <v-row justify="center" no-gutters>
                <v-btn v-for="item in menuItems" :key="item.title" :to="{ name: item.pathName }" text exact-active-class="activeSiteRoute" exact>
                    <router-link :to="{ name: item.pathName }">{{ item.title }} </router-link>
                </v-btn>
            </v-row>
        </v-toolbar>

        <v-toolbar flat dense class="d-sm-none" light>
          <v-app-bar-nav-icon @click.stop="sidebar = !sidebar"></v-app-bar-nav-icon>

          <v-toolbar-title>{{currentPage()}}</v-toolbar-title>
        </v-toolbar>

        <v-navigation-drawer v-model="sidebar" absolute temporary class="d-sm-none">
          <v-list dense nav>
            <v-list-item v-for="item in menuItems" :key="item.title" :to="{ name: item.pathName }" exact> 
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list> 
        </v-navigation-drawer>
    </nav>
</template>

<script>
export default {
  data() {
    return {
        sidebar: false,
        group: null,
        menuItems: [
            { title: 'Home', pathName: 'Home', alias: ''},
            { title: 'Big Data', pathName: 'bigData', alias: 'bigdata'}
        ],
        path: this.$route.path,
    }
  },

  methods: {
    currentPage() {
      if(new URL(window.location.href).pathname.includes('legal_disclaimer')) {
        return '';
      }
      return this.menuItems.filter(obj => obj.alias.toLowerCase() == new URL(window.location.href).pathname.split('/')[1])[0].title;
    }
  },

  watch: {
    group() {
      this.sidebar = false
    }
  }
}
</script>

<style>

#nav {
  display: flex;
  align-items: center;
  top: 0;
  background-color: white;
  z-index: 1;
  /*height: 50px;*/
  margin: 10px 0px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
  border: 0;
  text-align: center;
}

#nav a.activeRecipeRoute {
    color: grey;
    border: 0px;
}

.nav-links {
  list-style-type: none;
}

.links {
  padding-right: 20px;
  list-style: none;
  float: left;
}

.links:hover {
  text-decoration: none;
}

.v-toolbar {
  background-color: #2c3e50;
}
</style>