<template>
  <div class="home d-block justify-center align-center align-self-center">
    <p class="text-style text-h2">
      Welcome to UPXLAND
    </p>
    <p class="caption">
      developed by IKris (CrAazy#5217)
    </p>
    <p class="text-h4 services">
      UPXLAND-Services
    </p>
    <p>
      Use this website at your own risk! The information you find here is not financial advice!
    </p>
    <div>
      
    </div>
  </div>
</template>

<script>
export default {
  name: 'home'
}
</script>

<style scoped>

.text-style {
  margin: 60px 10px 0px 10px;
}

.services {
  margin: 60px 0 10px;
}
</style>