module.exports = Object.freeze({
    status: [
        { key: 'all', value: 'All'},
        { key: 'unlocked', value: 'Unlocked'},
        { key: 'owned', value: 'Owned'},
        { key: 'sale_all', value: 'For Sale'},
        { key: 'sale_upx', value: 'For Sale (UPX)'},
        { key: 'sale_fiat', value: 'For Sale ($)'},
        { key: 'locked', value: 'Locked'}
    ],
    fsa: [
        { key: 'all', value: 'All'},
        { key: 'fsa', value: 'Only FSA'},
        { key: 'no_fsa', value: 'Non FSA'}
    ],
    sortBy: [
        { key: 'mint_price', value: 'Mint Price'},
        { key: 'markup', value: 'Markup'},
        { key: 'up2', value: 'UP2'},
        { key: 'yield', value: 'Yield'},
        { key: 'sale_price', value: 'Sale price'}
    ],
    sort: [
        { key: 'asc', value: 'Ascending'},
        { key: 'desc', value: 'Descending'}
    ],
    building: [
        { key: 'all', value: 'All'},
        { key: 'building', value: 'With Building'}
    ]
})