<template>
  <div class="home justify-center align-center align-self-center text-h5">
    <div class="text-style">
      Big Data search for all properties in Upland
    </div>
    
    <div class="filter" style="margin:20px">
      <v-form ref="form" style="margin:20px" lazy-validation v-model="valid">
        <v-row>
          <v-autocomplete
            v-model="city"
            v-on:change="filterNeighborhoodsCollections"
            :items="citiesFile"
            color="black"
            item-text="value"
            item-value="key"
            label="City"
            placeholder="City"
            return-object
            dense
            auto-select-first
            class="text-input"
          ></v-autocomplete>
          <v-autocomplete
            v-model="neighborhoods"
            :items="neighborhoodsChoose"
            color="black"
            item-text="name"
            item-value="id"
            label="Neighborhood"
            return-object
            dense
            auto-select-first
            chips
            small-chips
            multiple
            clearable
            class="text-input"
          ></v-autocomplete>
          <v-autocomplete
            v-model="collections"
            :items="collectionsChoose"
            color="black"
            item-text="name"
            item-value="id"
            label="Collections"
            return-object
            dense
            auto-select-first
            chips
            small-chips
            multiple
            clearable
            class="text-input"
          ></v-autocomplete>
        </v-row>
        <v-row>
          <v-select v-model="statusOption" :items="sortOptions.status" item-text="value" item-value="key" label="Status" placeholder="Status" return-object dense class="text-input"></v-select>
          <v-select v-model="fsaOption" :items="sortOptions.fsa" item-text="value" item-value="key" label="FSA Option" placeholder="FSA Option" return-object dense class="text-input"></v-select>
          <v-select v-model="sortByOption" :items="sortOptions.sortBy" item-text="value" item-value="key" label="Sort By" placeholder="Sort By" return-object dense class="text-input"></v-select>
          <v-select v-model="sortOption" :items="sortOptions.sort" item-text="value" item-value="key" label="Sort direction" placeholder="Sort direction" return-object dense class="text-input"></v-select>
        </v-row>
        <v-row>
          <v-text-field label="Search for address" v-model="searchAddress" dense append-icon="mdi-magnify" class="text-input" :rules="[rules.searchText]"></v-text-field>
        </v-row>
        <v-row style="margin: 10px 10px">
          <v-btn block color="primary" outlined :loading="loading" @click="loadProps" class="" style="">Search</v-btn>
        </v-row>
      </v-form>
      <v-card style="margin: 30px">
        <v-card-title>
          Properties
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search Address"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headersTable"
          :items="result"
          :search="search"
          :footer-props="footerOptions"
          mobile-breakpoint="0"
          dense
        >
          <template v-slot:top>
            <v-dialog v-model="dialogPropInfo" max-width="800px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ propInformation.full_address }}, {{ propInformation.city.name }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <p class="text-h6" style="text-align:start">General Information</p>
                    <div style="display:flex;align-items:start;flex-wrap:wrap">
                      <v-text-field :value="propInformation.prop_id" label="Property ID" readonly style="max-width:150px;width:150px;margin-right:20px;display:inline-block"></v-text-field>
                      <v-text-field :value="propInformation.full_address" label="Address" readonly style="max-width:250px;width:250px;margin-right:20px;display:inline-block"></v-text-field>
                      <v-text-field :value="propInformation.city.name" label="City" readonly style="max-width:150px;width:150px;margin-right:20px;display:inline-block"></v-text-field>
                      <v-text-field :value="propInformation.last_purchased_price ? Number(propInformation.last_purchased_price).toLocaleString() : 'NaN'" label="Last Price" readonly style="max-width:120px;width:120px;margin-right:20px;display:inline-block"></v-text-field>
                    </div>

                    <p class="text-h6" style="text-align:start">Earnings</p>
                    <div style="display:flex;align-items:start;flex-wrap:wrap">
                      <v-text-field :value="getMonthlyEarnings" label="Monthly Earnings" readonly style="max-width:200px;width:200px;margin-right:20px;display:inline-block">Test</v-text-field>
                      <v-text-field :value="getBoostedEarnings" label="Boosted Earnings" readonly style="max-width:400px;width:400px;margin-right:20px;display:inline-block"></v-text-field>
                    </div>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogPropTransactions" max-width="700px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ propInformation.full_address }}, {{ propInformation.city.name }} - Transactions</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-timeline align-top dense style="max-width:600px" >
                      <v-timeline-item small v-for="(item, i) in propInformation.transactions" :key="i" color="grey" :icon="getTransactionIcon(item.type)" fill-dot>
                        <v-row class="pt-1">
                          <v-col cols="4" style="text-align:left;margin-right:20px">
                            <strong style="display:flex;align-items:start;flex-wrap:wrap">{{ new Date(item.timestamp).toLocaleString() }}</strong>
                          </v-col>
                          <v-col>
                            <strong v-if="item.buyer" style="display:flex;align-items:start">{{ item.buyer.username }}</strong>
                            <strong v-else style="display:flex;align-items:start">Upland</strong>
                            <div class="text-caption" style="text-align:left">
                              <p>{{ getTransactionText(item) }}</p>
                            </div>
                          </v-col>
                        </v-row>
                      </v-timeline-item>
                    </v-timeline>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogPropBuilding" max-width="800px" v-if="propInformation.building">
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ propInformation.full_address }}, {{ propInformation.city.name }} - Building Transactions</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-timeline align-top dense style="max-width:600px" >
                      <v-timeline-item small v-for="(item, i) in propInformation.building.transactions" :key="i" color="grey" :icon="getBuildingIcon(item.type)" fill-dot>
                        <v-row class="pt-1">
                          <v-col cols="4" style="text-align:left;margin-right:20px">
                            <strong style="display:flex;align-items:start;flex-wrap:wrap">{{ new Date(item.timestamp).toLocaleString() }}</strong>
                          </v-col>
                          <v-col>
                            <strong style="display:flex;align-items:start">{{ item.username }}</strong>
                            <div class="text-caption" style="text-align:left">
                              <p>{{ getBuildingText(item) }}</p>
                            </div>
                          </v-col>
                        </v-row>
                      </v-timeline-item>
                    </v-timeline>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
          </template>
          
          <template v-slot:[`item.collections`]="{ item }">
            <v-tooltip bottom v-for="coll in item.collections" :key="coll.id">
              <template v-slot:activator="{ on, attrs }">
                <v-icon medium class="" :color="getColorOfCollection(coll)" v-bind="attrs" v-on="on">mdi-star-circle</v-icon>
              </template>
              <span>{{ coll.name }} <br> Boost: {{coll.yield_boost}}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.mint_price`]="{ item }">
            <td v-if="item.mint_price" align-self="start" class="justify-center align-center align-self-center">{{ Number(item.mint_price).toLocaleString() }}</td>
            <td v-else align-self="start" class="justify-center align-center align-self-center"></td>
          </template>
          <template v-slot:[`item.last_purchased_price`]="{ item }">
            <td v-if="item.last_purchased_price" align-self="start" class="justify-center align-center align-self-center">{{ Number(item.last_purchased_price).toLocaleString() }}</td>
            <td v-else align-self="start" class="justify-center align-center align-self-center"></td>
          </template>
          <template v-slot:[`item.area`]="{ item }">
            <td align-self="start" class="justify-center align-center align-self-center">{{ Number(item.area).toLocaleString() }}</td>
          </template>
          <template v-slot:[`item.price`]="{ item }">
            <td align-self="start" v-if="item.on_market" class="justify-center align-center align-self-center">{{ Number(item.price).toLocaleString() }}</td>
            <td align-self="start" v-else class="justify-center align-center align-self-center"></td>
          </template>
          <template v-slot:[`item.on_market.markup`]="{ item }">
            <td align-self="start" v-if="item.on_market" class="justify-center align-center align-self-center">{{ Number(item.on_market.markup).toLocaleString() }}%</td>
          </template> 

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              medium
              class="mr-2"
              @click="seeItem(item)"
            >
               mdi-information-outline
            </v-icon>
            <v-icon
              medium
              class="mr-2"
              @click="seeItemTransactions(item)"
            >
              mdi-swap-horizontal-bold
            </v-icon>
            <v-icon
              v-if="item.building"
              medium
              class="mr-2"
              @click="seeItemBuilding(item)"
            >
              mdi-home-search-outline
            </v-icon>
          </template>          
        </v-data-table>
      </v-card>
      <v-row style="margin: 10px 30px">
        <v-btn block color="grey" outlined :loading="loading" @click="loadMoreProps" v-if="result.length != 0 && result.length % 200 == 0">Load More</v-btn>
      </v-row>
    </div>
    <v-snackbar
      v-model="snackbar"
    >
    Forminput is not valid!
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="too_many"
    >
    You cant load for than 10.000 props!
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="requestError"
    >
    Something went wrong, please try again!
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="requestError = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

import citiesFile from '../assets/data/cities.json';
import neighborhoodsFile from '../assets/data/neighborhoods.json';
import collectionsFile from '../assets/data/collections.json';
import sortOptions from '../assets/data/filterOptions';
// import testProps from '../assets/data/testProps.json';
import headersTableFile from '../assets/data/tableHeaders';
export default {
  name: 'home',

  data() {
    return {
      valid: false,
      snackbar: false,
      requestError: false,
      too_many: false,
      citiesFile,
      neighborhoodsChoose: neighborhoodsFile,
      collectionsChoose: collectionsFile,
      sortOptions,
      city: {"value": "San Francisco","key": 1},
      neighborhoods: [],
      collections: [],
      statusOption: { key: 'all', value: 'All'},
      fsaOption: { key: 'all', value: 'All'},
      sortByOption: { key: 'mint_price', value: 'Mint Price'},
      sortOption: { key: 'asc', value: 'Ascending'},
      searchAddress: '',
      result: [],
      skipAmount: 0,
      queryStringRequest: '',
      loading: false,
      search: '',
      headersTable: headersTableFile.bigData,
      footerOptions: {
        "items-per-page-options": [10, 50, 100, 200]
      },
      dialogPropInfo: false,
      dialogPropTransactions: false,
      dialogPropBuilding: false,
      propInformation: {
        prop_id: '',
        full_address: '',
        city: {
          name: ''
        },
        yield_per_hour: '',
        collections: [{ yield_boost: ''}],
        building: {transactions: []}
      },
      rules: {
        searchText: value => {
          if(value == '') {
            return true;
          }
          const pattern = /^[a-zA-Z0-9\s]+$/;
          return pattern.test(value) || 'Only alphanumeric characters allowed';
        }
        
      }
    }
  },

  created() {
    this.neighborhoodsChoose = neighborhoodsFile.filter((e) => e.city_id == 1);
    this.collectionsChoose = collectionsFile.filter((e) => e.city_id == 1);
  },

  methods: {
    filterNeighborhoodsCollections() {
      if(!this.city || this.city.key == 0) {
        this.neighborhoodsChoose = neighborhoodsFile;
        this.collectionsChoose = collectionsFile;
        return;
      }
      this.neighborhoodsChoose = neighborhoodsFile.filter((e) => e.city_id == this.city.key);
      this.collectionsChoose = collectionsFile.filter((e) => e.city_id == this.city.key);
      this.neighborhoods = [];
      this.collections = [];
    },

    async loadProps() {
      this.validate();
      // console.log(this.valid);
      if(!this.valid) {
        this.snackbar = true;
        this.searchAddress = '';
        return;
      }
      this.loading = true;
      this.result = [];

      let queryParams = new URLSearchParams({
        status: this.statusOption.key,
        fsa_option: this.fsaOption.key,
        sort_by: this.sortByOption.key,
        sort_dir: this.sortOption.key,
        city: this.city.key
      });

      if(this.neighborhoods.map(e => e.id).length > 0) {
        queryParams.append('neighborhood', this.neighborhoods.map(e => e.id).join(','));
      }
      if(this.collections.map(e => e.id).length > 0) {
        queryParams.append('collection', this.collections.map(e => e.id).join(','));
      }

      if(this.searchAddress.length > 0) {
        queryParams.append('address', this.searchAddress.toString());
      }

      this.skipAmount = 1;
      this.queryStringRequest = queryParams.toString();

      try {
        let raw = await fetch(`https://api.upxland.me/bigdata/props?${queryParams.toString()}`,{
          method: 'GET',
          mode: 'cors'
        });
        let status = raw.status;
        if(parseInt(status/100, 10) != 2) {
          this.requestError = true;
          this.loading = false;
          return;
        }
        let data = await raw.json();
        data.forEach((elem) => {
          if(elem.collections) {
            elem.collections.sort((a, b) => (a.category - b.category || a.yield_boost - b.yield_boost));
          }
          if(!elem.on_market) {
            delete elem.price;
          }
        });
        this.result = data;

        this.loading = false;
      } catch (error) {
        this.requestError = true;
        this.loading = false;
        return;
      }

    },

    async loadMoreProps() {
      this.loading = true;

      if(this.skipAmount > 50) {
        this.too_many = true;
        this.loading = false;
        return;
      }

      try {
        let raw = await fetch(`https://api.upxland.me/bigdata/props?${this.queryStringRequest}&skip=${this.skipAmount}`,{
          method: 'GET',
          mode: 'cors'
        });
        let status = raw.status;
        if(parseInt(status/100, 10) != 2) {
          this.requestError = true;
          this.loading = false;
          return;
        }
        let data = await raw.json();
        data.forEach((elem) => {
          elem.collections.sort((a, b) => (a.category - b.category || a.yield_boost - b.yield_boost));
          if(!elem.on_market) {
            delete elem.price;
          }
        });

        this.result = this.result.concat(data);
        this.skipAmount = this.skipAmount + 1;
        this.loading = false;
      } catch (error) {
        this.requestError = true;
        this.loading = false;
        return;
      }
    },

    seeItem(item) {
      this.propInformation = Object.assign({}, item);
      this.dialogPropInfo = true;
      // console.log(this.dialogPropInfo);
    },

    async seeItemTransactions(item) {
      this.propInformation = Object.assign({}, item);
      let raw = await fetch(`https://api.upxland.me/bigdata/transaction?prop_id=${item.prop_id}`,{
        method: 'GET',
        mode: 'cors'
      });
      let data = await raw.json();

      this.propInformation.transactions = data.transactions.sort((a,b) => new Date(b.timestamp) - new Date(a.timestamp));
      this.dialogPropTransactions = true;
    },

    seeItemBuilding(item) {
      this.propInformation = Object.assign({}, item);
      if(this.propInformation.building) {
        this.propInformation.building.transactions = this.propInformation.building.transactions.sort((a,b) => new Date(b.timestamp) - new Date(a.timestamp));
      }
      this.dialogPropBuilding = true;
    },

    getColorOfCollection(item) {
      if(item.category == 1) {
        return "rgb(23,75,198)";
      } else if(item.category == 2) {
        return "rgb(119,54,157)";
      } else if(item.category == 3) {
        return "rgb(254,168,1)";
      } else if(item.category == 4) {
        return "rgb(254,13,1)";
      } else if(item.category == 5) {
        return "rgb(228,221,1)";
      }
    },

    getTransactionIcon(type) {
      if(type == 'mint') {
        return 'mdi-pickaxe';
      } else if(type == 'swap') {
        return 'mdi-swap-horizontal';
      } else if(type == 'buy' || type == 'acceptedoffer') {
        return 'mdi-cash-multiple';
      } else if(type == 'visa expired') {
        return 'mdi-account-cancel ';
      } else if(type == 'burn') {
        return 'mdi-fire-circle';
      }
    },

    getTransactionText(trx) {
      if(trx.type == 'mint') {
        return `Minted for ${Number(trx.price).toLocaleString()} ${trx.currency}`;
      } else if(trx.type == 'swap') {
        return `Swaped for ${trx.for_prop}`;
      } else if(trx.type == 'buy') {
        return `Bought for ${Number(trx.price).toLocaleString()} ${trx.currency}`;
      } else if(trx.type == 'acceptedoffer') {
        return `Bought through offer for ${Number(trx.price).toLocaleString()} ${trx.currency}`;
      } else if(trx.type == 'visa expired') {
        return `Visa expired`;
      } else if(trx.type == 'burn') {
        return `Burned`;
      }
    },

    getBuildingIcon(type) {
      if(type == 'start') {
        return 'mdi-bank-outline';
      } else if(type == 'stake') {
        return 'mdi-bank-plus';
      } else if(type == 'unstake') {
        return 'mdi-bank-minus';
      } else if(type == 'end') {
        return 'mdi-bank-check'
      }
    },

    getBuildingText(trx) {
      if(trx.type == 'start') {
        return `Building started with ${Number(trx.spark_amount).toLocaleString()} Spark`;
      } else if(trx.type == 'stake') {
        return `Added ${Number(trx.spark_amount).toLocaleString()} Spark`;
      } else if(trx.type == 'unstake') {
        return `Pulled ${Number(trx.spark_amount).toLocaleString()} Spark`;
      } else if(trx.type == 'end') {
        return `Building finished`;
      }
    },

    validate() {
      this.$refs.form.validate()
    },

  },

  computed: {
    getMonthlyEarnings: function() {
      return `${Number(parseFloat(parseFloat(this.propInformation.yield_per_hour) * 720).toFixed(2)).toLocaleString()} UPX`;
    },

    getBoostedEarningsLow: function() {
      return this.propInformation.collections.length == 0 ? Number(parseFloat(parseFloat(this.propInformation.yield_per_hour) * 720).toFixed(2)).toLocaleString() : Number(parseFloat(parseFloat(this.propInformation.yield_per_hour) * 720 * parseFloat(this.propInformation.collections[0].yield_boost)).toFixed(2)).toLocaleString();
    },

    getBoostedEarningsHigh: function() {
      return this.propInformation.collections.length == 0 ? Number(parseFloat(parseFloat(this.propInformation.yield_per_hour) * 720).toFixed(2)).toLocaleString() : Number(parseFloat(parseFloat(this.propInformation.yield_per_hour) * 720 * parseFloat(this.propInformation.collections[this.propInformation.collections.length - 1].yield_boost)).toFixed(2)).toLocaleString();
    },

    getBoostedEarnings: function() {
      let earningsLow = this.propInformation.collections.length == 0 ? Number(parseFloat(parseFloat(this.propInformation.yield_per_hour) * 720).toFixed(2)).toLocaleString() : Number(parseFloat(parseFloat(this.propInformation.yield_per_hour) * 720 * parseFloat(this.propInformation.collections[0].yield_boost)).toFixed(2)).toLocaleString();
      let earningsHigh = this.propInformation.collections.length == 0 ? Number(parseFloat(parseFloat(this.propInformation.yield_per_hour) * 720).toFixed(2)).toLocaleString() : Number(parseFloat(parseFloat(this.propInformation.yield_per_hour) * 720 * parseFloat(this.propInformation.collections[this.propInformation.collections.length - 1].yield_boost)).toFixed(2)).toLocaleString();
      return `${earningsLow} UPX - ${earningsHigh} UPX`
    }

  }

}
</script>

<style scoped>

.text-input {
  margin: 10px 20px;
}

.text-style {
  /*height: 10%;*/
  margin: 60px 10px;
}
</style>