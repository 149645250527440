<template>
  <v-app>
    <the-navigation :key="$route.path" />
    
    <v-main>
      <router-view :key="$route.path"/>
    </v-main>

    <v-footer padless class="">
      <v-card flat tile width="100%" class="white text-center">
        <v-divider></v-divider>
        <v-card-text class="text-subtitle-2">
          <div>
            <p class="center"><strong>UPXLAND</strong></p>
            <a style="float:right;z-index:1;position:relative" href="/legal_disclaimer" >Legal Disclaimer</a>
          </div>
          
          
          
          <p ></p>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import TheNavigation from '@/components/TheNavigation';
export default {
  name: 'App',
  components: {
    TheNavigation,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.v-divider {
  width: calc(100% - 20px);
  margin: auto;
}

.footer-padding {
  /*height: 50px;*/
  margin-top: 20px !important;
}

.center {
    text-align:center;
    position: absolute;
    right: 0;
    left: 0;
}
</style>
