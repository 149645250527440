<template>
  <div class="text-justify box">
    <p class="text-h4">
      LEGAL DISCLAIMER
    </p>

    <p class="text-h5">
        CONTACT INFORMATION
    </p>
    <p>Email: upxland.contact@gmail.com</p>

    <p class="text-h5">
        WEBSITE DISCLAIMER
    </p>
    <p>
        The information provided by the Upxland Team ("we", "us", or "our") on <a href="https://www.upxland.me">https://www.upxland.me</a> (the "Site") is for general informational purposes only.
        All information on the Site is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on the Site.
        UNDER NO CIRCIMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE.
        YOUR USE OF THE SITE AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE IS SOLELY AT YOUR OWN RISK.
    </p>

  </div>
</template>

<script>
export default {
  name: 'home'
}
</script>

<style scoped>

.box {
  margin: 20px;
}
</style>